// Generated by Framer (cf240c2)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/tbiAmyP8q4mMaXLQcmj3/Video.js";
const VideoFonts = getFonts(Video);

const serializationHash = "framer-9R1TG"

const variantClassNames = {vaPPaMdER: "framer-v-uswaiq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "vaPPaMdER", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1ix3xiq = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 2750)
})

useOnVariantChange(baseVariant, {default: onAppear1ix3xiq})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uswaiq", className, classNames)} data-framer-name={"Primary"} data-highlight layoutDependency={layoutDependency} layoutId={"vaPPaMdER"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><motion.div className={"framer-1fdpufk"} layoutDependency={layoutDependency} layoutId={"RdNiZamoT"} style={{backgroundColor: "rgb(44, 44, 44)"}}><ComponentViewportProvider ><motion.div className={"framer-lrlzt0-container"} layoutDependency={layoutDependency} layoutId={"GwB2XSHFJ-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"GwB2XSHFJ"} isMixedBorderRadius={false} layoutId={"GwB2XSHFJ"} loop muted objectFit={"cover"} playing posterEnabled srcFile={"https://framerusercontent.com/assets/HJfrkTtMJBPLS6tK2jtQQs18rvs.mp4"} srcType={"Upload"} srcUrl={"https://xgjzloifyvgpbmyonaya.supabase.co/storage/v1/object/public/files/n3_bsJLnX6/original"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9R1TG.framer-1h8qpzz, .framer-9R1TG .framer-1h8qpzz { display: block; }", ".framer-9R1TG.framer-uswaiq { height: 585px; overflow: hidden; position: relative; width: 270px; will-change: var(--framer-will-change-override, transform); }", ".framer-9R1TG .framer-1fdpufk { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 100%; justify-content: center; left: 0px; overflow: visible; padding: 0px; position: absolute; top: 0px; width: 100%; }", ".framer-9R1TG .framer-lrlzt0-container { flex: none; height: 100%; position: relative; width: 99%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9R1TG .framer-1fdpufk { gap: 0px; } .framer-9R1TG .framer-1fdpufk > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-9R1TG .framer-1fdpufk > :first-child { margin-top: 0px; } .framer-9R1TG .framer-1fdpufk > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 585
 * @framerIntrinsicWidth 270
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhjSuCAU7m: React.ComponentType<Props> = withCSS(Component, css, "framer-9R1TG") as typeof Component;
export default FramerhjSuCAU7m;

FramerhjSuCAU7m.displayName = "EndlessFeedContent 2";

FramerhjSuCAU7m.defaultProps = {height: 585, width: 270};

addFonts(FramerhjSuCAU7m, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})